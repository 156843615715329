import { TemplateObject } from 'src/@types/customerPortalApi';
import { Snackbar, SnackbarType } from '@State/Snackbar/SnackbarProvider';

export enum FilterInputType {
  SingleChoice = 'singleChoice',
  MultiChoice = 'multiChoice',
  Boolean = 'boolean',
}

export enum FilterType {
  Workplaces = 'workplaces',
  ContainerTypes = 'containertypes',
  Materials = 'materials',
  Customer = 'customer',
  Month = 'month',
  Date = 'date',
  Deviation = 'deviation',
  HazardousWaste = 'hazardousWaste',
  Estates = 'estates',
  Articles = 'articles',
  StandardStatistics = 'standardStatistics',
  ArticleFilters = 'articleFilters',
  ArticleTypeFilters = 'articleTypeFilters',
  MaterialFilters = 'materialFilters',
  WorkplaceFilters = 'workplaceFilters',
  CustomerFilters = 'customerFilters',
}

export enum FilterEnum {
  Article = 'ARTICLE',
  ArticleTypes = 'ARTICLE_TYPES',
  Material = 'MATERIAL',
  Workplace = 'WORKPLACE',
  Customers = 'CUSTOMER',
}

export enum TemplateType {
  Estates = 'estates',
  MaterialsByMonth = 'materials-by-month',
  Services = 'services',
  Statistics = 'statistics',
  WasteHierarchyByMonth = 'waste-hierarchy-by-month',
}

export type FilterOption = {
  displayName: string;
  value: string | number;
};

export type FilterSettings = {
  id: FilterType;
  icon?: JSX.Element;
  defaultIndex?: number;
  defaultValue?: string | number;
  defaultLabel?: string;
  noClear?: boolean;
  query_param?: string;
  inputType?: FilterInputType;
};

export enum SortType {
  Workplaces = 'workplace',
  Materials = 'material',
  ScheduledCollections = 'scheduled_collections',
  Article = 'article',
  Reuse = 'reuse',
  Recycle = 'recycle',
  Energy = 'energy',
  Landfill = 'landfill',
  Waste = 'waste',
  Date = 'date',
  Weight = 'weight',
  Producer = 'producer',
  Receiver = 'receiver',
  ProducerCity = 'producerCity',
  ReceiverCity = 'producerCity',
}

export type SortSettings = {
  id: SortType;
  type: 'string' | 'number' | 'date';
};

export type SortOrder = 'asc' | 'desc';

export interface ISortBy {
  id: SortType;
  order: SortOrder;
}

export interface IFilter<T = FilterInputType.SingleChoice | FilterInputType.MultiChoice>
  extends FilterSettings {
  name: string;
  values: FilterOption[];
  default_text?: string;
  active_values: T extends FilterInputType.Boolean ? boolean[] : (string | number)[];
}

export enum DateTemplate {
  currentMonth = 'currentMonth',
  lastMonth = 'lastMonth',
  lastTwelveMonths = 'lastTwelveMonths',
  thisYear = 'thisYear',
  custom = 'custom',
}

export interface IDateFilter {
  date_to: string;
  date_from: string;
  template?: DateTemplate;
}

export interface IFilterResponseValues {
  id?: number;
  value?: number;
  name?: string;
  address?: string;
  number?: string;
  label?: string;
}

export type IFilterResponse = FilterSettings & {
  [key in FilterType]: IFilterResponseValues[];
};

export type PaginaitionLimit = 10 | 25 | 50 | 100;
export interface IPagination {
  page: number;
  limit: PaginaitionLimit;
}

export interface IFilterContext<T = any> {
  panelOpen: string | boolean;
  setPanelOpen: React.Dispatch<React.SetStateAction<string | boolean>>;
  filters: IFilter<any>[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter<any>[]>>;
  response: T & { number_of_rows?: number; numberOfRows?: number };
  dateFilter: IDateFilter | null;
  setDateFilter: React.Dispatch<React.SetStateAction<IDateFilter | null>>;
  pagination: IPagination | null;
  setPagination: React.Dispatch<React.SetStateAction<IPagination | null>>;
  numberOfRows: number | null;
  sortBy: ISortBy | null;
  setSortBy: React.Dispatch<React.SetStateAction<ISortBy | null>>;
  isLoading: boolean;
  isError: boolean;
  sortSettings?: SortSettings[];
  withDateFilter?: boolean;
  templates: TemplateObject[] | null;
  templateType?: TemplateType;
  showSnackbar: boolean;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  snackbar: Snackbar | null;
  populateSnackbar: (type: SnackbarType, message: String) => void;
}

export interface WithFilterContextProps {
  filterSettings: FilterSettings[];
  sortSettings?: SortSettings[];
  useQuery: any;
  useFilterQuery?: any;
  filterQueryType?: FilterType;
  withDateFilter?: boolean;
  defaultDate?: IDateFilter;
  templateType?: TemplateType;
  children?: React.ReactNode;
  withPagination?: boolean;
}

export interface FilterPanelProps {
  title: string;
  onClose?: () => void;
}

export interface IFilterButton {
  title: string | JSX.Element;
  id?: string;
  name?: string;
  icon?: JSX.Element;
  noClear?: boolean;
  style?: React.CSSProperties;
  isBoolean?: boolean;
}

export interface IFilterSectionProps {
  filter: IFilter;
  handleChange: (name: string, value: (string | number | boolean)[]) => void;
}

export interface IDateSectionProps {
  handleChange: ({ date_from, date_to, template }: IDateFilter) => void;
}

export interface IFullFilter {
  date_from?: string;
  date_to?: string;
  custom?: IFilter<any>[];
  date?: IDateFilter;
  pagination?: IPagination;
  sortBy?: ISortBy;
}

export interface IPreDefinedFilter<T = FilterInputType.SingleChoice | FilterInputType.MultiChoice> {
  id: string;
  activeValues: T extends FilterInputType.Boolean ? boolean[] : (string | number)[];
}

export type QueryOptions =
  | {
      camelCase?: boolean;
    }
  | undefined;
